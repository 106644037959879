import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PagesAuthentication from "../../helpers/PagesAuthentication";
import TitlesLabels from "../../helpers/TitlesLabels";
import UrlSlugs from "../../helpers/UrlSlugs";
import { checkingFollowDailyGlanceStatus, getNotificationListApi, notificationMaskRead } from "../../helpers/QueryDailyGlance";
import Utilities from "../../helpers/Utilities";
import { pageTitle, showFooter, showHeader } from "../../actions";

const GlanceNotificationList = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const APP_TITLE = process.env.REACT_APP_site_title;

    const descriptionLimit = 55;
    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();

    const [contentLoader, setContentLoader] = useState(true);
    const [notificationList, setNotificationList] = useState([]);
    const [groupIdSelected, setGroupIdSelected] = useState(0);


    const getNotificationList = async () => {
        setContentLoader(true);
        const notificationListResponse = await getNotificationListApi(profileLoggedin.account_id ?? 0);
        setContentLoader(false);
        //console.log(notificationListResponse);
        setNotificationList(notificationListResponse);
    }

    const expandList = (id = -1) => {
        id = parseInt(id);
        setGroupIdSelected(groupIdSelected === id ? 0 : id);
    }

    const viewArticle = async (detail = {}) => {

        if (Object.keys(detail.article_details ?? {}).length > 0) {
            /* const readStatus = parseInt(detail.read_status ?? 0);
            if (readStatus === 0) {
                await notificationMaskRead(detail.group_id ?? 0, profileLoggedin.account_id ?? 0);
            } */

            history.push(`/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(detail.article_details.group_id ?? 0)}/${btoa(detail.article_details.date.split(' ')[0] ?? '')}`);
        }
    }

    const loaderHtml = <li className="global-preloader">
        <div className="col-11 pl-0">
            <div className="bg-gray col-5 pt-3 border-radius-loader"></div>
            <div className="mt-2 bg-gray col-10 pt-3 border-radius-loader"></div>
        </div>
        <div className="col-1 pr-0">
            <div className="bg-gray pt-3 border-radius-loader"></div>
        </div>
    </li>

    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        const pageTitleText = Utilities.capitalize(TitlesLabels.general.notifications);
        dispatch(pageTitle(pageTitleText));
        dispatch(showHeader(1));
        dispatch(showFooter(1));

        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (!glanceStatus) {
            history.push(`/${UrlSlugs.settings_notifications}`);
            return false;
        }

        await getNotificationList();

    }, []);


    return (
        <div className="header-daily-glance">

            <div className="breadcrumbs  breadcrumbs-chat">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li><div className="current">{TitlesLabels.general.notifications}</div></li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container pt-4  px-5 pb-5">
                    {
                        contentLoader &&
                        <ul className="ul-global-listing mt-3">
                            {
                                [...Array(4 + (Math.floor(Math.random() * 3))).keys()].map((key) => loaderHtml)
                            }
                        </ul>
                    }

                    {
                        !contentLoader &&
                        <>
                            {
                                Object.keys(notificationList ?? []).length === 0 ?
                                    <div className="text-center text-lowercase no-record-label mt-5">{TitlesLabels.general.noRecordAvailable}</div>
                                    :
                                    <ul className="ul-global-listing cursor-pointer text-lowercase font-gotham-book fs-16 fs-md-16">
                                        {
                                            Object.entries(notificationList ?? []).map(([key, row]) =>
                                                <li key={key} >
                                                    <div className='line-height-1 w-100'>
                                                        <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1" data-name={row.title}>
                                                            <span onClick={() => viewArticle(row)}>{row.title}</span>
                                                        </div>
                                                        <div className="profile-label  color-theme-grey">
                                                            <div className="col-md-10 px-0" onClick={() => expandList(row.group_id)}>
                                                                {
                                                                    row.description.length > descriptionLimit ?

                                                                        groupIdSelected === parseInt(row.group_id ?? 0) ?
                                                                            row.description
                                                                            :
                                                                            <>
                                                                                {row.description.substring(0, descriptionLimit)}
                                                                                <span className="color-theme-green">...read more</span>
                                                                            </>


                                                                        : row.description
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-right color-theme-grey fs-16">{Utilities.formatDateList(row.create_date)}</div>
                                                </li>
                                            )
                                        }
                                    </ul>
                            }
                        </>
                    }
                </div>
            </div>

        </div>
    );

}

export default GlanceNotificationList;